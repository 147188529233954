import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Banner from "../components/common/Banner"
import tw, { styled } from 'twin.macro'
const StyledMainContainer = styled.div`
${tw`flex flex-col items-center justify-center container mx-auto md:px-8 px-5 py-12 bg-primary bg-opacity-5`};
p {
    ${tw`md:text-lg text-base font-medium text-muted pb-6 leading-normal`}
}
h3 {
  ${tw`text-xl text-secondary font-semibold pb-2`}
}
h4 {
  ${tw`text-lg text-orange font-bold pb-2`}
}
h5 {
  ${tw`text-lg text-primary-dark font-medium pb-2`}
}
ol, ul {
  li {
      ${tw`md:text-lg text-base font-medium text-muted leading-normal`}
      &::marker {
          ${tw`text-lg font-bold text-primary-dark`}
      }
  }
}
ol {
${tw`list-decimal pl-6`}
  li {
    ${tw`pb-2`}
    p {
      ${tw`pb-2`}
    }

    ul {
        ${tw`pl-4`}
        li {
            &::marker {
                ${tw`text-lg font-semibold text-primary-dark [content: "> "]`}
            }
        }
    }
  }
}
`;

const InspirationDetails = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Inspirations', link: '/inspirations' },
    { name: frontmatter.title, link: '' },
  ]
  return (
    <Layout>
      <Banner
        data={frontmatter.title}
        image={frontmatter.bannerImage.publicURL}
        crumbs={crumbs}
      />
      <StyledMainContainer>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </StyledMainContainer>
    </Layout>
  )
};

export default InspirationDetails

export const Head = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <SEO title={frontmatter.title} description={frontmatter.description} />
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        bannerImage {
          publicURL
        }
      }
    }
  }
`